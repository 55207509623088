
//import ConstUtils from '@/utils/ConstUtils.js';

export default class Utils {

  static SortByDate = function(a, b) {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  }
}