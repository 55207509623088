<template>

  <div v-if="hasPdfUrl">
    <a :href="pdfUrl" 
        target="_blank" 
        :disabled="disabled"
        class="rc-input-button-nosize rc-input-width rc-font-small rc-text-label">Download</a>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import ContentUtils from '@/utils/ContentUtils.js'
import Timesheet from '@/domain/model/timesheet/Timesheet.js'

export default {
  name: 'timesheet-pdf-link',
  components: {
  },
  props: {
    timesheetId: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  watch: {
    timesheetId() {
      this.domain.logger().info("timesheetId changed");
    }
  },
  computed: {
    ...mapGetters([
      'domain',
    ]),
    timesheet: function() {
      if (this.domain && this.timesheetId) {
        return this.domain.timesheets().findById(this.timesheetId);
      }
      return new Timesheet(this.domain);
    },
    pdfUrl: function() {
      if (!this.domain) {
        return "";
      }
      var url = ContentUtils.escape(this.timesheet.pdfUrl());
      url = url.replace("#", "%23");
      return url;
    },
    hasPdfUrl: function() {
      if (!this.domain) {
        return false;
      }
      return this.timesheet.hasPdfUrl();
    },
  },
  methods: {
    
  }
}
</script>