<template>
<Box>
  
  <ErrorRow :error="error" />
  
  <Row class="rc-text-label rc-text-medium">
    
    <div class="rc-box-col-11 rc-background-dark-thin rc-font-medium">
    
      <div class="container">
        
        <div class="row">
          <div class="rc-box-col-14">
            {{ C.get(K.FILTERS.TITLE) }}
          </div>
          <div class="rc-box-col-1">
            <Button 
              v-on:click="reset()" 
              :disabled="isLoading">
            Reset
            </Button>
          </div>
        </div>
        <div class="row">
          <div class="rc-box-col-1">
            {{ C.get(K.FILTERS.STATUS) }}
          </div>
          <div class="rc-box-col-1">
            <Button 
              v-on:click="setDefectFilterType('all')" 
              :disabled="isDefectFilterType('all')" >
            All
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button 
              v-on:click="setDefectFilterType('complete')" 
              :disabled="isDefectFilterType('complete')">
            Complete
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button 
              v-on:click="setDefectFilterType('inprogress')" 
              :disabled="isDefectFilterType('inprogress')">
            In Progress
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button
              v-on:click="setDefectFilterType('incomplete')" 
              :disabled="isDefectFilterType('incomplete')">
            Incomplete
            </Button>
          </div>
          <div class="rc-box-col-7" />
        </div> 
        <!-- row -->

        <div class="row">
          <div class="rc-box-col-1">
            {{ C.get(K.FILTERS.TIME) }}
          </div>
          <div class="rc-box-col-1">
            <Button 
              v-on:click="setFilterDate('all')" 
              :disabled="isDateFilter('all')">
            All
            </Button>
          </div>
          <div class="rc-box-col-1">
            <Button 
              v-on:click="setFilterDate('today')" 
              :disabled="isDateFilter('today')" >
            Today
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button 
              v-on:click="setFilterDate('yesterday')" 
              :disabled="isDateFilter('yesterday')" >
            Yesterday
            </Button>
          </div>

          <div class="rc-box-col-2">
            <Button 
              v-on:click="setFilterDate('last2Weeks')" 
              :disabled="isDateFilter('last2Weeks')">
            Last 2 Weeks
            </Button>
          </div>

          <div class="rc-box-col-2">
            <Button 
              v-on:click="setFilterDate('thisMonth')" 
              :disabled="isDateFilter('thisMonth')">
              This Month
            </Button>
          </div>
              
          <div class="rc-box-col-2">
            <Button 
              v-on:click="setFilterDate('last30Days')" 
              :disabled="isDateFilter('last30Days')">
            Last 30 Days
            </Button>
          </div >
                
          <div class="rc-box-col-2">
            <Button 
              v-on:click="setFilterDate('lastMonth')" 
              :disabled="isDateFilter('lastMonth')">
            Last Month
            </Button>
          </div>
          <div class="rc-box-col-2">
            <Button 
              v-on:click="setFilterDate('last2Years')" 
              :disabled="isDateFilter('last2Years')">
            Last 2 Years
            </Button>
          </div>
        </div> <!-- row -->
        
      </div> <!-- container -->
      
    </div> <!-- col-11 -->
    
    <div class="rc-box-col-4 rc-background-dark-thin rc-font-medium">

      <Box>
        <Row>
          <Column :width="10">Actions</Column>
          <Column :width="5">
            <Button v-on:click="refresh()" :disabled="isLoading">Refresh</Button>
          </Column>
        </Row>
        
        <Row> </Row>
        
      </Box>
     
    </div>
    
  </Row>
  
  <TitleRow class="rc-text-small">
    <Column :width="8" class="rc-text-left">Details</Column>
    <Column :width="5" class="rc-text-left">Hours</Column>
    <Column :width="2" class="rc-text-left">Status</Column>
  </TitleRow>
  
  <Row :showBorder="true">
    <Column>
      <Box>
        
        <div v-for="(object, index) in timesheets" v-bind:key="object['@rid']" :class="isEven(index) ? 'rowEven row' : 'rowOdd row'" style="padding: 8px">
          <div class="rc-box-col-15">
            <TimesheetSummary :isAdmin="isAdmin" :timesheetData="object" />
          </div>
        </div>
        
      </Box>
    </Column>
  </Row>
  
  <Row :showBorder="true" v-if="timesheets.length == 0 && !isLoading">
    <Column :width="5"> </Column>
    <Column :width="5">
        <Spacer />
        <Box :showBorder="true">
          <TitleRow :subtitle="true"> No Results </TitleRow>
          <Value align="left">
            There are no results for your current filters. Try using a filter 
            that can show data from a larger time window like:<br>
            <Box>
              <Row>
                <Column :width="10">
                  Show Hours for this month
                </Column>
                <Column :width="5">
                  <Button 
                    v-on:click="setFilterDate('thisMonth')" 
                    :disabled="isDateFilter('thisMonth')" >
                  This Month
                  </Button>
                </Column>
              </Row>
              <Row>
                <Column :width="10">
                  Show Hours for the last 2 years
                </Column>
                <Column :width="5">
                  <Button 
                    v-on:click="setFilterDate('last2Years')" 
                    :disabled="isDateFilter('last2Years')" >
                  Last 2 Years
                  </Button>
                </Column>
              </Row>
            </Box>
          </Value>
        </Box>
        <Spacer />
    </Column>
    <Column :width="5"> </Column>
  </Row>
      
  <LoadingRow :showBorder="true" v-if="isLoading"> {{ loadingMessage }} </LoadingRow>

  <Row :showBorder="true" v-if="!isLoading && timesheets.length > 0" >
    <div class="rc-box-col-4" />
    <div class="rc-box-col-1">
      <Button v-if="canPreviousPage()" v-on:click="previousPage"> &lt; </Button>
    </div>
    
    <div class="rc-box-col-5 rc-text-centre rc-text-label">
      Showing {{ min }} to {{ max }}  of {{ total }}
    </div>

    <div class="rc-box-col-1">
      <Button v-if="canNextPage()" v-on:click="nextPage"> &gt; </Button>
    </div>
      
    <div class="rc-box-col-4" />
  </Row>
</Box> <!-- container -->
</template>

<script>
import { mapGetters } from 'vuex'

import StringUtils from '@/utils/StringUtils.js'
import Utils       from './Utils.js'
//import ConstUtils  from '@/utils/ConstUtils.js'
import MC from "@/domain/session/MC.js";

import TimesheetMap from '@/domain/model/timesheet/TimesheetMap.js';
import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";
import Employee from "@/domain/model/employee/Employee.js";

import ErrorRow from '@/components/row/ErrorRow.vue'

import TimesheetSummary from './TimesheetSummary.vue'

import Catelog             from "@/domain/session/Catalog.js";
import CatelogKeys         from "@/domain/session/CanadianEnglish.js";
import TimesheetListFilter from "@/domain/model/timesheet/TimesheetListFilter.js";

import Button from "@/portals/shared/library/button/Button.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import Spacer from "@/portals/shared/library/spacer/SpacerRow.vue";
import Value from "@/portals/shared/library/value/Value.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

export default {
  name: 'portal-timesheet-list',
  components: {
    ErrorRow, Value, LoadingRow,
    Button, TitleRow, Spacer,
    TimesheetSummary,
    Box, Row, Column,
  },
  props: {
    employeeData: { type: Object, default: null },
    vehicle: { type: Object, default: null },
    start: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      C: new Catelog(),
      K: CatelogKeys.KEYS,
      timesheets: [],
      timesheetMap: new TimesheetMap(this.domain),
      isLoading: false,
      loadingMessage: null,
      defectTypeFilter: 'all',
      currentDateFilter: 'last30Days',
      total: 0,
      pageSize: 20,
      pageNumber: 0,
      employeeList: [],
      employeeMap: new EmployeeMap(this.domain),
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.total < t ? this.total : t;
    },
    min: function() {
      if (this.total == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
  },
  watch: {
    start() {
      if (this.start) {
        if (this.$route.query.time) {
          this.currentDateFilter = this.$route.query.time;
        }
        if (this.$route.query.status) {
          this.defectTypeFilter = this.$route.query.status;
        }
        this.sendInitialRequest();
      }
    }
  },
  mounted: function () {
    if (this.start) {
      if (this.$route.query.time) {
        this.currentDateFilter = this.$route.query.time;
      }
      if (this.$route.query.status) {
        this.defectTypeFilter = this.$route.query.status;
      }
      this.sendInitialRequest();
    }
  },
  
  methods: {
    sendInitialRequest: function() {
      this.loadByFilter();
    },
    
    loadByFilter: function () {
      this.timesheets = [];
      this.employeeList = [];
      
      this.employeeMap = new EmployeeMap(this.domain);
      this.loadTimesheetsByFilter();
    },
    
    loadTimesheetsByFilter: function() {
      this.timesheets = [];
      this.isLoading = true;
      this.loadingMessage = this.MC.Timesheet.Status.Loading.value();
      
      var filter = new TimesheetListFilter();
      filter
        .withDateRange(this.currentDateFilter)
        .withPagination(this.pageSize, this.pageNumber)
        .withStatusType(this.defectTypeFilter)
        .done();
      if (this.employeeData) {
        this.employeeMap.add(new Employee(this.domain, this.employeeData));
      }
      filter
        .withEmployees(this.employeeMap.pointers())
        .done();
      
      filter
        .withCompany(this.domain.session().company().id())
        .done();
      
      var event = this.domain
          .events()
          .timesheets().list(filter);
      event.send(this.timesheetListListener);
    },
    
    timesheetListListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        this.total = payload.count();
        var map = payload.valuesFor(TimesheetMap.MODEL_NAME);
        if (map) {
          var list = Object.values(map.data);
          this.timesheetMap = new TimesheetMap(this.domain, map.data);
          this.timesheets = list.sort(Utils.SortByDate);
        }
      }
      this.isLoading = false;
    },
    
    isDateFilter: function (key) {
      if (this.currentDateFilter === key) {
        return true;
      }
      return false;
    },
    setFilterDate: function (key) {
      this.pageNumber = 0;
      this.currentDateFilter = key
      this.loadByFilter();
    },
    isDefectFilterType: function(key) {
      if (this.defectTypeFilter === key) {
        return true;
      }
      return false;
    }, 
    setDefectFilterType: function(type) {
      if (type) {
        this.defectTypeFilter = type;
        this.pageNumber = 0;
      }
      this.loadByFilter();
    },
    
    reset: function () {
      this.setFilterDate('today');
      this.setDefectFilterType('all');
      this.pageNumber = 0;
      this.loadByFilter();
    },
    
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    
    refresh: function () {
      this.loadByFilter();
    },
    
    previousPage: function() {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.loadByFilter();
      }
    },
    canPreviousPage: function() {
      return this.pageNumber > 0;
    },
    canNextPage: function() {
      return this.total > (this.pageSize * this.pageNumber) + this.pageSize;
    },
    nextPage: function() {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.loadByFilter();
      }
    },
    
  },
}
</script>