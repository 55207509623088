<template>
  <Box>
    <Row>
      <Column>
        <div class="rc-container rc-font-small">
        
          <div class="rc-row">
            
            <div class="rc-box-col-8">
              <div class="rc-container">
                <StringValueRow label="Date:" :value="timesheet.dateDisplay()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12"/>
                <StringValueRow label="User:" :value="timesheet.employee().find().user().find().fullName()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12"/>
                <StringValueRow label="Start Location:" :value="timesheet.locationStart()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12"/>
                <StringValueRow label="End Location:" :value="timesheet.locationEnd()" labelWidth="rc-box-col-3" valueWidth="rc-box-col-12"/>
              </div>
            </div>
            <div class="rc-box-col-5">
              <div class="rc-container">
                <StringValueRow label="On Duty Driving:" :value="timesheet.onDutyDrivingHoursDisplay()" labelWidth="rc-box-col-7" valueWidth="rc-box-col-8"/>
                <StringValueRow label="On Duty Not Driving:" :value="timesheet.onDutyNotDrivingHoursDisplay()" labelWidth="rc-box-col-7" valueWidth="rc-box-col-8"/>
                <StringValueRow label="On Duty Total:" :value="timesheet.onDutyHoursDisplay()" labelWidth="rc-box-col-7" valueWidth="rc-box-col-8"/>
                <SpacerRow />
                <StringValueRow label="Off Duty Total:" :value="timesheet.offDutyHoursDisplay()" labelWidth="rc-box-col-7" valueWidth="rc-box-col-8"/>
                <SpacerRow />
                <StringValueRow label="Duty Time Total:" :value="timesheet.totalHoursDisplay()" labelWidth="rc-box-col-7" valueWidth="rc-box-col-8"/>
              </div>
            </div>
            
            <div class="rc-box-col-2">
              <div class="rc-inline-row">
                <div class="rc-box-col-15 rc-text-centre" :class="toResultColour()" > {{ timesheet.statusDisplay() }} </div>
              </div>
              <div class="rc-inline-row">
                <div class="rc-box-col-15 rc-text-value"> {{ timesheet.cycleDisplay() }} </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </Column>
    </Row>
    
    <Row v-if="isAdmin" :showBorder="true">
      <div class="rc-box-col-2 rc-text-label">Company</div>
      <div class="rc-box-col-3 rc-text-value">
        <Value>{{ timesheet.company().find().name() }}</Value>
      </div>
      <div class="rc-box-col-1" v-if="!timesheet.isNew() && !timesheet.company().isNew()">
        <Button v-on:click="companyDetails(timesheet.company().id())">Details</Button>
      </div>
      <div class="rc-box-col-9" />
    </Row>
          
    <StringValueRow v-if="isAdmin" label="id" :value="timesheet.id()" />
    <StringValueRow v-if="isAdmin" label="Created Date" :value="timesheet.createdDateDisplay()" />
    <StringValueRow v-if="isAdmin" label="Modified Date" :value="timesheet.modifiedDateDisplay()" />
    
    <Row>
      <div class="rc-box-col-2">
        <Button :disabled="false" v-on:click="details">
          Details
        </Button>
      </div>
      <Column :width="2">
        <PdfLink v-if="timesheet.hasPdfUrl()" :timesheetId="timesheet.id()" />
      </Column>
      <div class="rc-box-col-9"></div>
      <Column :width="2">
        <Button v-if="isAdmin" :disabled="buildingPdf" v-on:click="buildPdf">
          Build Pdf
        </Button>
      </Column>

    </Row>
  </Box>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentUtils from '@/utils/ContentUtils.js'
import ConstUtils from '@/utils/ConstUtils.js'
import Timesheet from '@/domain/model/timesheet/Timesheet.js';
import Catalog    from "@/domain/session/Catalog.js";
import StringValueRow from '@/components/row/StringValueRow.vue';
import SpacerRow from '@/components/row/SpacerRow.vue';
import Value from "@/portals/shared/library/value/Value.vue";

import Button from "@/portals/shared/library/button/Button.vue";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
//import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import PdfLink from "./TimesheetPdfLink.vue";

export default {
  name: 'portals-timesheet-summary',
  components: {
    Button, Value,
//    TitleRow,
    Box, Row, Column,
    StringValueRow,
    SpacerRow,
    PdfLink,
  },
  props: {
    timesheetData: Object,
    displayVehicleName: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      images: {},
      C: new Catalog(),
      buildingPdf: false,
    }
  },
  watch: {
    //watch
  },
  computed: {
    ...mapGetters([
      "auth_connected",
      'domain',
    ]),
    timesheet: function() {
      if (this.timesheetData) {
        return new Timesheet(this.domain, this.timesheetData);
      }
      return new Timesheet(this.domain, {});
    }
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    }
  },
  methods: {
    decode: function (value) {
      return ContentUtils.unescape(value)
    },
    toResultColour: function () {
      var result = this.timesheet.status();
      if (result == Timesheet.Values.Status.COMPLETED) {
        return 'rc-input-good';

      } else if (this.timesheet.isStatusCompleteOffDay()) {
        return 'rc-input-black';

      } else if (result == Timesheet.Values.Status.INPROGRESS) {
        return 'rc-input-inprogress';
            
      } else if (result == Timesheet.Values.Status.INCOMPLETE) {
        return 'rc-input-minor';
            
      } else {
        return 'rc-input-major';
      }
    },
    details: function() {
      var params = {
          id: this.timesheet.id(),
        }
        this.$router.push({
          name: ConstUtils.ROUTES.TIMESHEET.DETAILS,
          params: params,
        })
    },
    buildPdf: function() {
      this.buildingPdf = true;
      var event = this.domain
          .events()
          .timesheets().rebuildPdf(this.timesheet.id());
      event.send(this.buildPdfListener);
    },
    buildPdfListener: function(response) {
      this.buildingPdf = false;
      if (response) {
        //
      }
    },
    companyDetails: function (companyId) {
      var params = {
          id: companyId, 
      };
      this.$router.push( {
            name: ConstUtils.ROUTES.COMPANY.ADMIN.DETAILS,
            params: params,
          });
    },
  },
}
</script>
